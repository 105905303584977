import { SET_POPUP_STATUS } from '../actions';

const initialState = { popupOpen: false, modalStudyProcedureOpen: false };

export default (previousState = initialState, action) => {
    const { type, payload } = action;
    if (type === SET_POPUP_STATUS) {
        return { ...previousState, ...payload.data };
    } return previousState;
};
