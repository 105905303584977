import { put, takeEvery } from 'redux-saga/effects';

import { setTabKey } from '../actions/tab';

function* handleFetchProgress(action) {
    // handle change tab when fetch progress done with action have tab
    console.log('handle tab change side effect', action);
    const { tab } = action.meta;
    const { tabKey } = tab;
    yield put(setTabKey(tabKey));
    // yield call(TabsManager.tabSelect, context, tabKey);
}

const takeFetchAction = (action) => (action.meta && action.meta.tab);

export default function* watchTabChange() {
    yield takeEvery(takeFetchAction, handleFetchProgress);
}
