export default {
    name: 'Department',
    fields: {
        type: 'Department type',
        description: 'Description',
        key: 'Key',
        name: 'Department name'
    },
    title: {
        list: 'List',
        create: 'Create',
        edit: 'Edit'
    },
    clinical: {
        title: 'Clinical',
        isClinical: 'Yes',
        nonClinical: 'No'
    }
};
