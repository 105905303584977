export default {
    tab: {
        main: 'Studies list',
        receiptionist: 'Receiptionist'
    },
    dashboard: {
        main: 'Statistic',
        studies: 'Summary studies',
        doctor: 'Summary results',
        synthesis: 'Synthesis Screen'
    },
    study: {
        main: 'Manage Study',
        list: 'All of Studies',
        unread: 'New Studies',
        unconfirm: 'Unconfirmed Studies',
        confirmed: 'Confirmed Studies',
        tele: 'TeleRadiology Studies'
    },
    receiptionist: {
        title: 'Scan room',
        main: 'All of Patients',
        pending: 'Patient waiting for reception',
        modalityInfo: ''
    },
    upload: {
        createSingle: 'Upload a study',
        createMulti: 'Upload studies',
        create: 'Upload Study',
        created: 'Created Studies',
        creating: 'Creating Studies'
    },
    registration: 'Registration',
    examination: {
        done: 'Finished Orders',
        list: 'Orders List'
    },
    patient: 'Patients',
    // order: 'Radiology Orders',
    passwordChange: 'Change password',
    modalitiesList: 'Modalities List',
    profile: 'Profile',
    administrator: {
        main: 'Administration',
        user: 'Manage Users',
        role: 'Manage Roles',
        authority: 'Manage Authorities',
        modality: 'Manage Modalities',
        template: 'Manage Templates',
        department: 'Manage Department',
        uploadStudy: 'Create Study',
        profile: 'Profile',
        audit: 'Activities History',
        service: 'Service',
        store: 'PACS Store',
        createStudy: 'Create DICOM Study',
        createNonStudy: 'Create non-DICOM Study',
        generalCreateStudy: 'Create study',
        activity: 'Activity Log',
        group: 'Group config',
        hospitalGroup: 'Doctor group',
        pdfTemplate: 'Pdf template',
        webcamNonDicom: 'Create non-DICOM Study from Modality'
    },
    technician: {
        main: 'Modality information'
    },
    scanning: {
        waiting: 'Waiting list',
        studying: 'Studying list'
    },
    studyBookmark: {
        list: 'Study bookmark'
    },
    generatePdf: {
        list: 'Statistic report'
    },
    order: {
        create: 'Create order'
    },
    registrationStats: {
        list: 'Tracking screen'
    },
    tabGroup: {
        main: 'Management',
        resource: {
            user: 'User',
            modality: 'Modality',
            department: 'Department',
            role: 'Role',
            modalityGroup: 'Modality Group',
            procedure: 'Procedure',
            template: 'Content template',
            pdfTemplate: 'Layout template'
        }
    }
};
