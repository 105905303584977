export default {
    name: 'Studies',
    fields: {
        accessionNumber: 'Accession No',
        studyDate: 'Study Date',
        studyTime: 'Study Time',
        bodyPartExamined: 'Body Part',
        bodyPart: 'Body Part',
        modality: 'Modality',
        modalityShort: 'Modality',
        hospital: 'Hospital',
        numOfImages: 'Images',
        numOfSeries: 'Number of Series',
        status: 'Status',
        stage: 'Status',
        description: 'Study Description',
        voidStatus: 'Available status',
        images: 'Images',
        imagesView: 'View Images',
        pid: 'Patient ID',
        pidShort: 'PID',
        patientName: 'Patient Name',
        patientId: 'Patient Id',
        birthDate: 'Date of birth',
        age: 'Age',
        gender: 'Gender',
        priority: 'Priority',
        orderPriority: 'Priority',
        assignee: 'Assignee',
        modalityName: 'Modality Name',
        modalityId: 'Modality Name',
        modalityNameShort: 'Modality Name',
        approval: 'Approval',
        assigneeName: 'Dignosis Doctor',
        modalityType: 'Modality Type',
        studyModalityType: 'Modality Type',
        modalityTypeShort: 'Type',
        modalityAETitle: 'AE_Title',
        myAssign: 'My Assign',
        myApprove: 'My Approve',
        reportStatus: 'Report Status',
        completedTime: 'Completed Time',
        includeMeta: 'Hide patient\'s information',
        groupName: 'Group',
        studyInstanceUid: 'Study Instance UID',
        numOfFiles: 'Number of Files',
        requestDate: 'Request Date',
        requestDepartment: 'Request Department',
        referringPhysician: 'Referring Physician',
        note: 'Note',
        requestNumber: 'Request Number',
        diagnosis: 'Diagnosis',
        group: 'Group',
        radiologist: 'Radiologist',
        numSeriesAndImages: 'Series/Image',
        order: 'Order',
        studyType: 'Study type',
        numOfInstances: 'Number of Instances',
        anonymized: 'Anonymized',
        stt: 'No.',
        studyDescription: 'Description',
        birthYear: 'Birth Year'
    },
    status: {
        draft: 'Draft',
        unread: 'Unread',
        readed: 'Read, not approved',
        approved: 'Approved',
        voided: 'Deleted'
    },
    statusFilter: {
        notStarted: 'Not started',
        inProgress: 'In progress',
        pendingApproval: 'Pending approval',
        approval: 'Approval',
        done: 'Done',
        cancelled: 'Cancelled',
        voided: 'Voided'
    },
    report: {
        all: 'All',
        unread: 'Unread',
        waiting: 'Waiting Confirmed',
        done: 'Confirmed',
        yes: 'Yes',
        no: 'No'
    },
    title: {
        popupFilter: 'Fields for filter',
        default: 'Default',
        info: 'Information',
        history: 'History',
        readedHistory: 'Read History',
        reportHistory: 'Report history',
        similarStudies: 'Similar studies',
        selectAttachment: 'Select Attachment',
        list: 'Studies List',
        download: 'Download',
        tele: 'IRad',
        mapOrder: 'Map Order',
        detail: 'Detail',
        template: 'Template'
    },
    button: {
        editReport: 'Edit report',
        capturePhoto: 'Capture photo',
        refresh: 'Refresh',
        studyDetail: 'Study detail',
        addProcedure: 'Add procedure',
        selectTemplate: 'Template'
    },
    filter: {
        accessionNumber: 'Accession Number',
        patientName: 'Patient',
        addFilter: 'Add filter',
        datePickingFilter: 'Choose date start',
        procedureName: 'Procedure name'
    }
};
