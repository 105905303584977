import { Guardian, MessageCode } from 'it-reactjs-ui-components';
import moment from 'moment';

import { dateStoreFormat, uuidv4 } from '../../utils';
import { COLOR_BLACK, COLOR_VIOLET } from '../logging';
import {
  ALL_SAVE,
  ALL_AUTHORITIES,
  AUTH_BASIC,
  AUTH_ID,
  AUTH_REMEMBER,
  AUTH_ROLES,
  AUTH_TOKEN,
  AUTH_USER,
  AUTH_USERNAME
} from './authUtils';
import CONST_VALUES from '../../utils/constValues';

const clearData = () => ALL_SAVE.forEach((key) => localStorage.removeItem(key));

let permissions = JSON.parse(localStorage.getItem(AUTH_ROLES));

Guardian.getInstance().setMasterAuthority(ALL_AUTHORITIES.SYSADMIN);

const hostName = window.location.host;

export default {
  login: (params) => {
    console.groupCollapsed('%cAUTH_PROVIDER %cLOGIN', COLOR_VIOLET, COLOR_BLACK);
    console.log('params', params);
    const { username, password, remember } = params;
    const request = new Request(process.env.REACT_APP_AUTH_URL.replace(CONST_VALUES.HOSTNAME, hostName), {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      headers: new Headers({
        'Content-Type': 'application/json',
        'Corporate-Id': process.env.REACT_APP_NAME,
        'Request-Id': uuidv4(),
        'Request-Time': moment().format(dateStoreFormat)
      })
    });
    return fetch(request)
      .then(
        (response) => {
          if (response.code < 200 || response.code >= 300) {
            console.groupEnd();
            throw new Error(response.statusText);
          }
          return response.json();
        },
        (error) => {
          console.error('login error', error);
          // toast.error(`Cannot login. Please try again later!`);
          console.groupEnd();
          return Promise.reject(error);
        }
      )
      .then(async (data) => {
        console.log('login data', data);
        const { header, body: user } = data;
        const { code, message } = header;

        if (code === MessageCode.OK || code === MessageCode.FOUND) {
          const { access_token, id } = user;

          localStorage.setItem(AUTH_BASIC, `${username}:${password}`);
          localStorage.setItem(AUTH_TOKEN, access_token); // base64 encode
          localStorage.setItem(AUTH_USERNAME, username);
          localStorage.setItem(AUTH_REMEMBER, remember);
          localStorage.setItem(AUTH_ID, id);
          localStorage.setItem(AUTH_USER, JSON.stringify(user));
          permissions = user.roles;

          console.groupEnd();
          return Promise.resolve({ data: user });
        }
        console.error(code, message);
        console.groupEnd();
        return Promise.reject(message);
      });
  },
  logout: () => {
    console.groupCollapsed('%cAUTH_PROVIDER %cLOGOUT', COLOR_VIOLET, COLOR_BLACK);
    console.log('logout');
    clearData();
    console.groupEnd();
    return Promise.resolve();
  },
  checkAuth: () => {
    console.groupCollapsed('%cAUTH_PROVIDER %cCHECK_AUTH', COLOR_VIOLET, COLOR_BLACK);
    console.groupEnd();
    return localStorage.getItem(AUTH_ID) ? Promise.resolve() : Promise.reject();
  },
  checkError: (params = {}) => {
    console.groupCollapsed('%cAUTH_PROVIDER %cCHECK_ERROR', COLOR_VIOLET, COLOR_BLACK);
    const { message, status, body } = params;
    console.log('fetch error', message, status, body);
    console.groupEnd();
    return status === 500 || status === 401 || status === 403 || status === 404 ? Promise.reject() : Promise.resolve();
  },
  getPermissions: () => {
    console.groupCollapsed('%cAUTH_PROVIDER %cGET_PERMISSIONS', COLOR_VIOLET, COLOR_BLACK);
    console.log(permissions);
    console.groupEnd();
    return permissions ? Promise.resolve(permissions) : Promise.reject();
  }
};
