export default {
    SKULL: 'Xương sọ',
    CSPINE: 'Cột sống cổ',
    TSPINE: 'Cột sống ngực',
    LSPINE: 'Cột sống thắt lưng',
    SSPINE: 'Xương cùng',
    COCCYX: 'Xương cụt',
    CHEST: 'Ngực',
    CLAVICLE: 'Xương đòn',
    BREAST: 'Nhũ',
    ABDOMEN: 'Bụng',
    PELVIS: 'Xương chậu',
    HIP: 'Háng',
    SHOULDER: 'Vai',
    ELBOW: 'Khuỷu tay',
    KNEE: 'Đầu gối',
    ANKLE: 'Cổ chân',
    HAND: 'Bàn tay',
    FOOT: 'Bàn chân',
    EXTREMITY: 'Chi',
    HEAD: 'Sọ não',
    BRAIN: 'Não',
    HEART: 'Tim',
    NECK: 'Cổ',
    LEG: 'Cẳng chân',
    ARM: 'Cẳng tay',
    JAW: 'Xương hàm',
    LIVER: 'Gan',
    'BRAIN,HEAD': 'Sọ não',
    'SKULL,JAW': 'Xương sọ',
    'CSPINE,TSPINE,LSPINE': 'Cột sống',
    'CLAVICLE,SHOULDER': 'Vai',
    'CHEST,BREAST,LIVER': 'Ngực',
    'ELBOW,ARM,HAND': 'Tay',
    'PELVIS,HIP': 'Háng',
    'KNEE,LEG,FOOT,ANKLE': 'Chân'
};
