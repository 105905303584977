export default {
  name: 'Lịch sử hoạt động',
  fields: {
    requestTime: 'Thời gian',
    username: 'Tên tài khoản',
    fullName: 'Tên người dùng',
    auditLogType: 'Loại hoạt động',
    description: 'Mô tả',
    added: 'Mục thêm',
    deleted: 'Mục xóa',
    reportBefore: 'Kết quả ban đầu',
    reportAfter: 'Kết quả đã sửa',
    reportApproved: 'Kết quả đã duyệt',
    searchByUsername: 'Tìm theo tên đăng nhập'
  },
  messages: {
    HTTP_REQUEST: 'Hành động thông thường',
    LOGIN: 'Đăng nhập',
    SEND_STUDY: 'Đã gửi ca chụp có mã ca chụp %{studyId} - %{patientName}',
    APPROVE_REPORT: 'Đã duyệt kết quả của ca chụp %{studyId} - %{patientName}',
    REJECT_REPORT: 'Báo lỗi kết quả',
    OVERWRITE_REPORT: 'Đã lưu kết quả của ca chụp %{studyId} - %{patientName}',
    COMPLETE_REPORT: 'Lưu kết quả',
    REJECT_APPROVED_REPORT: 'Đã hủy duyệt của ca chụp %{studyId} - %{patientName}'
  },
  actionType: {
    HTTP_REQUEST: 'Hành động thông thường',
    LOGIN: 'Đăng nhập',
    SEND_STUDY: 'Gửi ca chụp',
    APPROVE_REPORT: 'Duyệt kết quả',
    REJECT_REPORT: 'Báo lỗi kết quả',
    OVERWRITE_REPORT: 'Lưu kết quả sau báo lỗi của ca chụp',
    COMPLETE_REPORT: 'Lưu kết quả',
    REJECT_APPROVED_REPORT: 'Hủy kết quả đã duyệt ca chụp'
  }
};
