export const TELE_AUTHORIZATION = 'IT/TELE_AUTHORIZATION';
export const TELE_AUTHORIZATION_SUCCESS = 'IT/TELE_AUTHORIZATION_SUCCESS';

/**
 *  @param callback {function} : callback when auth success
 */
export const showTeleAuthorization = (callback) => ({
    type: TELE_AUTHORIZATION,
    payload: { callback }
});

export const hideTeleAuthorization = showTeleAuthorization;

/**
 *  @param authorizationCode {object} : authorization code from tele service
 */
export const teleAuthSuccess = (authorizationCode) => ({
    type: TELE_AUTHORIZATION_SUCCESS,
    payload: { authorizationCode }
});
