export default {
    name: 'Modality',
    title: {
        list: 'List',
        create: 'Create',
        edit: 'Edit'
    },
    fields: {
        id: 'Id',
        name: 'Name',
        title: 'Modality',
        aeTitle: 'AE Title',
        ipAddress: 'IP',
        port: 'Port',
        type: 'Modality Type',
        description: 'Description',
        modelName: 'Model Name',
        brand: 'Brand',
        rent: 'Rental Type',
        modalityProviderId: 'Provider',
        modalityType: 'Modality Type',
        isScanner: 'Is scanning device',
        enabled: 'Enabled',
        roomNo: 'Room No',
        prefixStudyIUID: 'StudyIUID Prefix',
        supportWL: 'Support Worklist',
        nonDicomSendWL: 'Non-DICOM send image'
    },
    notification: {
        duplicateModality: 'AE Title %{aeTitle} and IP Address %{ipAddress} already existed. Please try again',
        cannotDelete: 'Cannot delete field because it being used'
    },
    label: {
        today: 'Today',
        modality: 'Modality',
        status: 'Status',
        totalStudies: 'totalStudies',
        pending: 'Pending'
    }
};
