export const assignments = {
    name: 'Nhận ca',
    fields: {
        radiologistName: 'Bác sĩ đọc',
        radiologistId: 'Tên bác sĩ',
        doctor: 'Bác sĩ',
        title: 'Chức danh',
        creationDate: 'Thời điểm nhận ca',
        expiredTime: 'Dự kiến trả kết quả'
    }
};
