import { CLEAR_STATE } from 'react-admin';
import { ADD_TAB, CLOSE_TAB, SET_LAST_HOME_URL, SET_LIST_TAB, SET_SUB_LAST_HOME_URL, SET_TAB_KEY, UPDATE_TAB } from '../actions/tab';
import { generateTab } from '../../utils/generateTab';
import { URL_STUDY_LIST_ALL } from '../../pages/RoutingConfig';

export const TAB_STATE = 'tab-state';

const initialState = {
    lastHomeUrl: '/',
    subLastHomeUrl: URL_STUDY_LIST_ALL,
    tabKey: 'home',
    listTab: []
};

// const defaultState = JSON.parse(localStorage.getItem(TAB_STATE)) || initialState;
// const defaultState = JSON.parse(localStorage.getItem(TAB_STATE) || JSON.stringify(initialState));

export const namespace = 'tab';

const tabReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_TAB: {
            const { newTab } = action.payload;
            return {
                ...state,
                listTab: [
                    ...state.listTab,
                    newTab
                ]
            };
        }
        case SET_TAB_KEY: {
            const { tabKey } = action.payload;
            return {
                ...state,
                tabKey
            };
        }
        case SET_LAST_HOME_URL: {
            const { url } = action.payload;
            return {
                ...state,
                lastHomeUrl: url
            };
        }
        case SET_SUB_LAST_HOME_URL: {
            const { url } = action.payload;
            return {
                ...state,
                subLastHomeUrl: url
            };
        }
        case SET_LIST_TAB: {
            const { listTab } = action.payload;
            return {
                ...state,
                listTab
            };
        }
        case UPDATE_TAB: {
            const { tabData, basePath } = action.payload;
            if (!tabData) {
                return state;
            }
            const newTab = generateTab(
                tabData,
                basePath
            );
            const key = newTab.key;
            const newListTab = state.listTab.map((tab) => {
                if (tab.key === key) {
                    return newTab;
                }
                return {
                    ...tab
                };
            });
            return {
                ...state,
                listTab: newListTab
            };
        }
        case CLOSE_TAB: {
            const { key } = action.payload;
            return {
                ...state,
                listTab: state.listTab.filter((tab) => tab.key !== key)
            };
        }
        case CLEAR_STATE:
            return initialState;
        default:
            return state;
    }
};

export default tabReducer;
