import { CLEAR_STATE } from 'react-admin';
import { URL_STUDY_LIST_ALL } from '../../pages/RoutingConfig';
import { SET_MOBILE_CONFIG, UPDATE_META_MOBILE_CONFIG } from '../actions/mobileConfig';
import { STUDY } from '../resources/resources';

export const MOBILE_CONFIG_STATE = 'topbar-state';

const initialState = {
    url: URL_STUDY_LIST_ALL,
    type: 'list',
    resource: STUDY,
    isHomePage: true,
    listOption: [],
    backURL: URL_STUDY_LIST_ALL,
    meta: {}
};

const mobileConfigReducer = (previousState = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_MOBILE_CONFIG:
        case UPDATE_META_MOBILE_CONFIG: return { ...previousState, ...payload };
        case CLEAR_STATE: return initialState;
        default: return previousState;
    }
};

export default mobileConfigReducer;
