import { get } from 'lodash';
import { createSelector } from 'reselect';

import { namespace } from '../reducers/tab';

export const getTab = (state) => get(state, ['iTech', namespace], {});

export const getLastHomeUrl = createSelector(
    getTab,
    (tab) => get(tab, 'lastHomeUrl', '')
);

export const getSubLastHomeUrl = createSelector(
    getTab,
    (tab) => get(tab, 'subLastHomeUrl', '')
);

export const getTabKey = createSelector(
    getTab,
    (tab) => get(tab, 'tabKey', '')
);

export const getListTab = createSelector(
    getTab,
    (tab) => get(tab, 'listTab', [])
);

export const getCurrentLocation = (state) => state.router.location.pathname;
