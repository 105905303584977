export default {
    label: {
        confirm: 'Study of patient <b>%{patientName}</b>, accession number is <b>%{accessionNumber}</b> has been performed?',
        finishSuccess: 'Finish study successful',
        finishFailure: 'Can\'t finish study. Please try again or contact administrator'
    },
    title: {
        schedule: 'Schedule study',
        callSpeaker: 'Call speaker'
    },
    button: {
        schedule: 'Schedule study',
        waitingOrder: 'Waiting list',
        callSpeaker: 'Call speaker'
    },
    notification: {
        notChooseProcedure: 'Please choose procedure',
        passedFailure: 'Can\'t passed order of patient <b>%{patientName}</b>. Please try again or contact administrator',
        passedSuccessful: 'Passed order of patient <b>%{patientName}</b> successful',
        goScanFailure: 'The patient <b>%{patientName}</b> cannot be taken into the room. Please try again or contact administrator',
        goScanSuccessful: 'The patient <b>%{patientName}</b> was taken to the scan room'
    }
};
