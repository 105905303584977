export const SEARCH_ONE = 'IT/SEARCH_ONE';
export const SEARCH_ONE_SUCCESS = 'IT/SEARCH_ONE_SUCCESS';

export const searchOne = (resource, filter, callback, notFoundMsg) => ({
    type: SEARCH_ONE,
    payload: { filter },
    meta: {
        resource,
        onDone: { callback },
        onNotFound: notFoundMsg && {
            notification: typeof notFoundMsg === 'string' ? { body: notFoundMsg, level: 'warning' } : {
                ...notFoundMsg,
                level: 'warning'
            }
        }
    }
});
