export default {
    title: {
        list: 'Patients Management',
        edit: 'Edit patient',
        create: 'Add patient',
        history: 'History',
        info: 'Patient Information',
        dicom: 'Dicom Information',
        reviewPdf: 'Review For Patient Information'
    },
    notification: {
        duplicateId: 'The patient id already exists. Please enter a new id',
        duplicateIdentifier: '%{identifierTypeId} %{identifier} already exists.',
        create: {
            success: 'Add new patient success',
            failure: 'Cannot add new patient. Please try again or contact Administrators.'
        },
        update: {
            success: 'Update patient\'s information success',
            failure: 'Cannot update patient\'s information. Please try again or contact Administrators.'
        }
    }
};
