export const ADD_TAB = 'tab/ADD_TAB';
export const ADD_TAB_SAGA = 'tab/ADD_TAB_SAGA';
export const CLOSE_TAB = 'tab/CLOSE_TAB';
export const CLOSE_TAB_SAGA = 'tab/CLOSE_TAB_SAGA';
export const ACTIVE_TAB_SAGA = 'tab/ACTIVE_TAB_SAGA';
export const SET_TAB_KEY = 'tab/SET_TAB_KEY';
export const SET_LAST_HOME_URL = 'tab/SET_LAST_HOME_URL';
export const SET_SUB_LAST_HOME_URL = 'tab/SET_SUB_LAST_HOME_URL';
export const SET_LIST_TAB = 'tab/SET_LIST_TAB';
export const UPDATE_TAB = 'tab/UPDATE_TAB';
export const SELECT_TAB_SAGA = 'tab/SELECT_TAB_SAGA';
export const REPLACE_TAB_SAGA = 'tab/REPLACE_TAB_SAGA';
export const REPLACE_CURRENT_TAB = 'tab/REPLACE_CURRENT_TAB';

const defaultBasePath = '/'; // basePath for home url

export const addTab = (newTab) => ({
    type: ADD_TAB,
    payload: {
        newTab
    }
});

export const addTabSaga = ({ tabData, basePath = defaultBasePath }) => ({
    type: ADD_TAB_SAGA,
    payload: {
        tabData,
        basePath
    }
});

export const closeTab = (key) => ({
    type: CLOSE_TAB,
    payload: {
        key
    }
});

export const closeTabSaga = (key) => ({
    type: CLOSE_TAB_SAGA,
    payload: {
        key
    }
});

export const activeTabSaga = ({ key, tab }) => ({
    type: ACTIVE_TAB_SAGA,
    payload: {
        key,
        tab
    }
});

export const setTabKey = (tabKey) => ({
    type: SET_TAB_KEY,
    payload: {
        tabKey
    }
});

export const setLastHomeUrl = (url) => ({
    type: SET_LAST_HOME_URL,
    payload: {
        url
    }
});

export const setSubLastHomeUrl = (url) => ({
    type: SET_SUB_LAST_HOME_URL,
    payload: {
        url
    }
});

export const setListTab = (listTab) => ({
    type: SET_LIST_TAB,
    payload: {
        listTab
    }
});

export const updateTab = ({ tabData, basePath = defaultBasePath }) => ({
    type: UPDATE_TAB,
    payload: {
        tabData,
        basePath
    }
});

export const selectTab = (key) => ({
    type: SELECT_TAB_SAGA,
    payload: {
        key
    }
});

export const replaceTab = ({ key, newTabData, basePath = defaultBasePath }) => ({
    type: REPLACE_TAB_SAGA,
    payload: {
        key,
        newTabData,
        basePath
    }
});

export const replaceCurrentTab = ({ newTabData, basePath = defaultBasePath }) => ({
    type: REPLACE_CURRENT_TAB,
    payload: {
        newTabData,
        basePath
    }
});
