export default {
    title: {
        show: 'Radiology Report',
        result: 'Radiology Report %{patientName} - %{studyDate}',
        annotationGroup: 'Save annotations'
    },
    mobile: {
        show: 'Radiology Report'
    },
    error: 'Cannot download report. Please try again or contact admin!',
    loading: 'Downloading report...',
    label: {
        draft: '(Draft)',
        radiologist: 'Radiologist',
        reportVersion: 'Version',
        teleReport: 'Tele Reports',
        localReport: 'Local Report',
        teleDesination: 'Radiologist Group',
        log: 'Telerad Report Logs',
        logLocal: 'Local Report Logs',
        attachments: 'Attachments',
        approval: 'Agree to always approve the diagnosis',
        procedure: 'Procedure: %{procedureName}'
    },
    notification: {
        update: 'Are you sure to change this report of patient %{patientName} ?',
        error: 'Are you sure to change this report? Press "Confirm" button to edit the content of report',
        confirm: {
            label: 'Do you want to confirm the diagnostic result?',
            before: 'Do you agree to confirm the diagnostic results for the %{patientName}\'s study?',
            success: 'The study of patient %{patientName} has been confirmed successful',
            fail: 'Cannot confirm the result of diagnostic. Please contact administrators!'
        },
        askConfirm: 'Hệ thống sẽ tự động duyệt kết quả chẩn đoán sau 60 phút. Bạn có muốn duyệt ngay kết quả chẩn đoán không?',
        expiredTime: 'Time out for error message!!!',
        editApprovedReport: 'Are you sure to change this approved report for the <b>%{patientName}</b>\'s study?',
        unApproveFail: 'Cannot confirm the result of diagnostic. Please contact adminstrators!',
        cantApprove: '<b>%{procedureName}</b> procedure has a approved report! Please check again.',
        cantPrintReport: 'Cannot print the result of diagnostic. Please contact adminstractors!',
        cantCompleteReport: 'This procedure has been approved, can\'t save report ! Please check again.',
        mediaNotFound: 'Cannot connect record device!',
        serverFailed: 'Cannot connect to server',
        completeSuccess: 'Completed report of study <b>%{patientName}</b>'
    },
    button: {
        confirmReport: 'Confirm report',
        cancelAssign: 'Cancel Assign',
        printReport: 'Print Report',
        attachment: 'Select attachments',
        viewReport: 'View report',
        selectAll: 'Select all',
        unSelect: 'UnSelect',
        reset: 'Reset',
        record: 'Record',
        stopRecord: 'Stop'
    }
};
