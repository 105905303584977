export default {
    title: {
        list: 'Activity History',
        detail: 'Activity Detail'
    },
    type: {
        default: 'Default',
        login: 'Login',
        send_study: 'Send study',
        approve_report: 'Approve report',
        reject_report: 'Reject report',
        overwrite_report: 'Save report',
        change_report: 'Change report',
        complete_report: 'Complete_report'
    }
};
