export default {
    SKULL: 'Skull',
    CSPINE: 'Cervical Spine',
    TSPINE: 'Thoracic Spine',
    LSPINE: 'Lumbar Spine',
    SSPINE: 'Sacrum Spine',
    COCCYX: 'Coccyx',
    CHEST: 'Chest',
    CLAVICLE: 'Clavicle',
    BREAST: 'Breast',
    ABDOMEN: 'Abdomen',
    PELVIS: 'Pelvis',
    HIP: 'Hip',
    SHOULDER: 'Shoulder',
    ELBOW: 'Elbow',
    KNEE: 'Knee',
    ANKLE: 'Ankle',
    HAND: 'Hand',
    FOOT: 'Foot',
    EXTREMITY: 'Extremity',
    HEAD: 'Head',
    BRAIN: 'Brain',
    HEART: 'Heart',
    NECK: 'Neck',
    LEG: 'Leg',
    ARM: 'Arm',
    JAW: 'Jaw',
    LIVER: 'Liver',
    'BRAIN,HEAD': 'HEAD',
    'SKULL,JAW': 'SKULL',
    'CSPINE,TSPINE,LSPINE': 'SPINE',
    'CLAVICLE,SHOULDER': 'SHOULDER',
    'CHEST,BREAST,LIVER': 'CHEST',
    'ELBOW,ARM,HAND': 'ARM',
    'PELVIS,HIP': 'HIP',
    'KNEE,LEG,FOOT,ANKLE': 'LEG'
};
