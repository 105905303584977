export default {
    title: {
        list: 'Template List',
        create: 'Create Template',
        edit: 'Edit Template'
    },
    notification: {
        empty: 'Content of results and conclusions must not be empty',
        duplicateName: 'Template name <b>%{templateName}</b> already exists. Please try a new name',
        delete: {
            confirm: 'Are you sure you want to delete this result pattern?'
        }
    },
    button: {
        clone: 'Clone'
    }
};
