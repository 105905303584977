export default {
    name: 'Role',
    fields: {
        id: 'ID',
        name: 'Role name',
        description: 'Description',
        authorities: 'Authorities',
        roles: 'Roles',
        parentRoleIds: 'Inherited roles',
        edit: 'Edit'
    },
    title: {
        list: 'List',
        edit: 'Edit',
        create: 'Create'
    }
};
