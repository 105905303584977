export default {
    title: {
        list: 'Users Management',
        edit: 'Edit user',
        create: 'Add user',
        changePassword: 'Change password',
        createUser: 'Add user'
    },
    filter: {
        username: 'Find user on username',
        role: 'Find user on role',
        includeDisableUser: 'Include Disabled Users'
    },
    text: {
        loginInfo: 'Account info',
        demographicInfo: 'Demographic info',
        passwordInfo: 'Create a password',
        defaultPassword: 'Default password: iT123456'
    },
    notification: {
        duplicateUsername: 'Username already exists. Please choose another username',
        create: {
            success: 'Create new user success',
            failure: 'Cannot create a new user. Please try again or contact administrators.'
        },
        update: {
            success: 'Update user information success',
            failure: 'Cannot update user information. Please try again or contact administrators.'
        },
        password: { update: 'Bạn có chắc chắn muốn thay đổi mật khẩu của tài khoản %{userName}?' },
        updateModality: {
            success: 'Add modality group is sucessful',
            fail: 'Can\'t add modality group. Please try again or contact admin'
        }
    },
    button: {
        help: 'Help'
    },
    mobile: {
        userInfo: 'User info'
    }
};
