export default {
    label: {
        confirm: 'Ca chụp của bệnh nhân <b>%{patientName}</b>, mã chỉ định <b>%{accessionNumber}</b> đã được thực hiện xong?',
        finishSuccess: 'Ca chụp đã được thực hiện xong',
        finishFailure: 'Không thể kết thúc ca chụp. Vui lòng thử lại hoặc liên hệ quản trị viên'
    },
    title: {
        schedule: 'Xếp lịch chụp',
        callSpeaker: 'Gọi loa'
    },
    button: {
        schedule: 'Xếp lịch chụp',
        waitingOrder: 'Màn hình STT',
        callSpeaker: 'Gọi loa'
    },
    notification: {
        notChooseProcedure: 'Xin vui lòng chọn dịch vụ chụp',
        passedFailure: 'Không thể bỏ qua ca chụp của bệnh nhân <b>%{patientName}</b>. Xin vui lòng thử lại hoặc liên hệ quản trị viên',
        passedSuccessful: 'Đã bỏ qua ca chụp của bệnh nhân <b>%{patientName}</b>',
        goScanFailure: 'Không thể cho bệnh nhân <b>%{patientName}</b> vào phòng chụp. Xin vui lòng thử lại hoặc liên hệ quản trị viên',
        goScanSuccessful: 'Đã cho bệnh nhân vào <b>%{patientName}</b> phòng chụp'
    }
};
