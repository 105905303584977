export const procedure = {
    name: 'Service',
    title: {
        list: 'List',
        create: 'Create',
        edit: 'Edit'
    },
    fields: {
        name: 'Service name',
        other: 'Service other',
        modalityType: 'Modality type',
        modalityTypeService: 'Modality type',
        bodyPartService: 'Body part',
        bodyPart: 'Body part',
        serviceId: 'Service ID',
        serviceName: 'Service name',
        preferred: {
            title: 'Preferred',
            isPreferred: 'Yes',
            nonPreferred: 'No'
        },
        procedureCode: 'Procedure code',
        studyDescription: 'DICOM description'
    },
    notification: {
        duplicateCode: '%{procedureCode} already existed. Please try again'
    },
    action: {
        choose: 'Choose procedure'
    },
    label: {
        tableTemplate: 'List of template content'
    }
};

export default procedure;
