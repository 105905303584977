import { cleanObject } from '../../utils';

export const SELECT_TEMP_DATA = 'IT/SELECT_TEMP_DATA';
export const REMOVE_TEMP_DATA = 'IT/REMOVE_TEMP_DATA';

/**
 * @param resource {string} : resource name
 * @param data {Object} : selected data
 * @param basePath {String} : base url for redirect behaviour
 * @param redirectTo {string} : view to redirect (list, create, edit..)
 * @param tab {Object} : tab object need to select
 */
export const selectTempData = (resource, data, basePath, redirectTo, tab) => {
    const meta = { basePath, redirectTo, tab };
    cleanObject(meta);
    return {
        type: SELECT_TEMP_DATA,
        payload: { data, resource },
        meta
    };
};

export const removeTempData = (resource) => ({
    type: REMOVE_TEMP_DATA,
    payload: { resource }
});
