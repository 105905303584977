import { messages } from 'it-reactjs-ui-components';

import viewer from './viewer';
import sidebar from './sidebar';
import resources from './resources';
import bodyPart from './bodyPart';
import modalityTypes from './modalityTypes';
import page from './page';
import validate from './validate';

messages.en.commons.message.delete = 'Do you really want to delete this %{resourceName}?';

export const en = {
    ...messages.en,
    viewer,
    page,
    sidebar,
    resources,
    label: {
        image: 'image',
        images: 'images'
    },
    priority: {
        0: 'Normal',
        1: 'Normal',
        2: 'Emergency',
        3: '3',
        4: '4',
        5: '5'
    },
    order_priority: {
        routine: 'Routine',
        urgent: 'Urgent',
        stat: 'Emergency',
        scheduled: 'Scheduled'
    },
    bodyPart,
    ...modalityTypes,
    deleteReason: 'Reasons for delete',
    'no-access': 'You don\'t have permissions to access this page. Please go back or contact Administrators!',
    'Username/Password invalid': 'Username or password is not correct. Please try again.',
    '': '',
    title: {
        viewer: 'Images',
        studies: 'Details'
    },
    confirm: {
        yes: 'Yes',
        no: 'No'
    },
    validation: validate,
    'max-tab': 'Existing tabs take up too much memory.\nPlease delete other tabs to open more'
};

export default en;
