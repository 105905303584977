import { combineReducers } from 'redux';
import selectTempData from './selectTempData';
import teleAuthorization from './teleAuthorization';
import currentUser from './currentUser';
import downloadStudies from './downloadStudies';
import tab, { namespace as tabNamespace } from './tab';
import appConfig from './appConfig';
import {
    APP_CONFIG,
    TEMP_DATA_REDUCER,
    TELE_AUTHORIZATION_REDUCER,
    DOWNLOAD_STUDIES_DATA,
    CURRENT_USER,
    MOBILE_MODE,
    MOBILE_CONFIG,
    POPUP_STATUS,
    MODALITY_INFO
} from './reducerKeys';
import checkMobileMode from './checkMobileMode';
import mobileConfig from './mobileConfig';
import popupStatus from './popupStatus';
import modalityInfo from './modalityInfo';

export default combineReducers({
    [TEMP_DATA_REDUCER]: selectTempData,
    [TELE_AUTHORIZATION_REDUCER]: teleAuthorization,
    [CURRENT_USER]: currentUser,
    [DOWNLOAD_STUDIES_DATA]: downloadStudies,
    [tabNamespace]: tab,
    [APP_CONFIG]: appConfig,
    [MOBILE_MODE]: checkMobileMode,
    [MOBILE_CONFIG]: mobileConfig,
    [POPUP_STATUS]: popupStatus,
    [MODALITY_INFO]: modalityInfo
});

export * from './reducerKeys';
