export default {
  title: {
    list: 'Danh sách bệnh nhân',
    edit: 'Chỉnh sửa thông tin bệnh nhân',
    create: 'Thêm bệnh nhân',
    history: 'Lịch sử',
    info: ' Thông tin bệnh nhân',
    dicom: 'Thông tin DICOM',
    reviewPdf: 'Duyệt lại thông tin bệnh nhân',
    patientID: 'Mã BN',
    patientName: 'Tên BN',
    birthYear: 'Năm sinh',
    gt: 'Giới Tính',
    address: 'Địa chỉ'
  },
  notification: {
    duplicateId: 'Mã bệnh nhân đã tồn tại. Vui lòng nhập mã mới',
    duplicateIdentifier: '%{identifierTypeId} <b>%{identifier}</b> đã tồn tại.',
    create: {
      success: 'Thêm bệnh nhân mới thành công',
      failure: 'Không thể thêm bệnh nhân. Vui lòng thử lại hoặc liên hệ quản trị viên.'
    },
    update: {
      success: 'Cập nhật thông tin bệnh nhân thành công',
      failure: 'Không thể chỉnh sửa thông tin bệnh nhân. Vui lòng thử lại hoặc liên hệ quản trị viên.'
    }
  }
};
