export default {
    title: {
        list: 'Order List',
        edit: 'Edit Radiology Order',
        create: 'Create Radiology Order',
        patientInfo: 'Patient Information',
        request: 'Request Information',
        procedure: 'Procedure Info',
        info: 'Request Information',
        findOrder: 'Find order'
    },
    notification: {
        patientNotFound: 'Patient not found. Please create new patient\'s info!',
        studyNotFound: 'Study for accession number %{accessionNumber} not found!',
        duplicateOrderId: 'The order id already exists. Please enter a new order id',
        orderNotFound: 'Order not found!',
        confirm: 'Please confirm to finish order of patient <b>%{patientName}</b>',
        confirmSuccess: 'Order of patient <b>%{patientName}</b> have been completed',
        confirmError: 'Please confirm to cancel order of patient <b>%{patientName}</b>',
        confirmErrorSuccess: 'Order of patient <b>%{patientName}</b> have been cancelled successfully',
        inprogress: 'The order is in progress',
        notStarted: 'The order is not started',
        notChooseModality: 'Please choose modality',
        create: {
            missingProcedure: 'Service is required',
            missingModality: 'Modality is required',
            failure: 'Can\'t create new order. Please try again or contact administrators',
            successful: 'Create new order successful'
        },
        schedule: {
            failure: 'Can\t schedule for patient <b>%{patientName}</b>. Please try again or contact administrators',
            successful: 'Schedule for patient <b>%{patientName}</b> successfully',
            updateFailure: 'Can\t update schedule for patient <b>%{patientName}</b>. Please try again or contact administrators',
            updateSuccessful: 'Schedule for patient <b>%{patientName}</b> successful'
        },
        update: {
            success: 'Order has been successfully updated!'
        },
        complete: {
            success: 'Order has been completed!'
        },
        suspend: {
            success: 'Order has been suspended!'
        },
        cancel: {
            success: 'Order has been successfully cancelled!'
        }
    }
};
