export default {
    title: {
        list: 'Ca chụp đã tạo',
        create: 'Tạo ca chụp'
    },
    notification: {
        delete: 'Ban có chắc chắn muốn xóa ca chụp của bệnh nhân <b>%{patientName}</b> không?',
        create: {
            success: 'Ca chụp tải lên đang được xử lý',
            fail: 'Không thể tải ca chụp do kết nối mạng. Vui lòng thử lại hoặc liên hệ quản trị viên!'
        },
        confirm: {
            before: 'Bạn có chắc chắn muốn tạo ca chụp này',
            success: 'Xác nhận ca chụp đẩy lên thành công'
        }
    },
    label: {
        numberOfFiles: 'Tổng số tệp đã chọn: %{numberOfFiles}',
        fileName: 'Tên file',
        fileSize: 'Kích thước',
        fileType: 'Định dạng',
        preview: 'Hình ảnh',
        deleteFile: 'Xóa file',
        page_rows_per_page: 'Số tệp mỗi trang:',
        no_results: 'Không có file đang chọn',
        chooseFolder: 'Chọn thư mục',
        chooseFiles: 'Chọn tệp',
        onlyDICOM: 'Chỉ chọn các thư mục chứa ảnh DICOM',
        chooseStudy: 'Chọn mỗi ca chụp bên dưới để thay đổi thông tin',
        create: 'Tải lên ca chụp mới',
        studyInfo: 'Thông tin ca chụp',
        inprogress: 'Đang xử lý'
    }
};
