const ORDER_STAGE = {
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED'
};

const REPORT_STAGE = {
  DRAFT: 'DRAFT',
  COMPLETED: 'COMPLETED',
  APPROVED: 'APPROVED'
};

const REGISTRATION_STATUS_STATS = {
  PENDING: 'PENDING',
  SCHEDULED: 'SCHEDULED',
  INPROGRESS: 'INPROGRESS',
  UNPROCESSED: 'UNPROCESSED',
  COMPLETED: 'COMPLETED'
};

const STUDY_STAGE = {
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  APPROVED: 'APPROVED',
  DONE: 'DONE',
  CANCELLED: 'CANCELLED'
};

const DEFAULT_PER_PAGE = 20;
const DEFAULT_PER_PAGE_STUDY = 50;
const ROWS_PER_PAGE_OPTIONS = [20, 50, 100];

const ATTACHMENT_TYPES = 'image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document';

export const HOTKEYS = {
  LOCK_STUDY: 'Alt + L',
  UNLOCK_STUDY: 'Alt + U',
  APPROVE_REPORT: 'Alt + A',
  SAVE_REPORT: 'Alt + S',
  ADD_REPORT: 'Alt + R',
  EDIT_REPORT: 'Alt + E',
  PRINT_REPORT: 'Alt + P',
  ADD_PROCEDURE: 'Alt + N',
  SELECT_TEMPLATE: 'Alt + T',
  VIEW_IMAGE: 'Alt + V',
  USE_MICROPHONE: 'Alt + M',
  TAKE_PICTURE: 'Space',
  START_TAKE_PICTURE: 'Escape',
  START_VOICE_REPORT: 'Alt + M',
  STOP_VOICE_REPORT: 'Alt + Q'
};

const ORDER_PRIORITY = {
  ROUTINE: 'ROUTINE',
  URGENT: 'URGENT',
  STAT: 'STAT',
  SCHEDULED: 'SCHEDULED'
};

const HOSTNAME = '<HOSTNAME>';

export default {
  ORDER_STAGE,
  DEFAULT_PER_PAGE,
  DEFAULT_PER_PAGE_STUDY,
  ROWS_PER_PAGE_OPTIONS,
  REGISTRATION_STATUS_STATS,
  ATTACHMENT_TYPES,
  REPORT_STAGE,
  STUDY_STAGE,
  HOTKEYS,
  ORDER_PRIORITY,
  HOSTNAME
};
