import { memoize } from 'lodash';
import moment from 'moment';
import { CREATE, DELETE, DELETE_MANY, GET_LIST, GET_MANY, GET_MANY_REFERENCE, GET_ONE, UPDATE, UPDATE_MANY } from 'react-admin';
import { MessageCode } from 'it-reactjs-ui-components';
import { dateStoreFormat, uuidv4 } from '../../utils';
import { AUTH_TOKEN } from './authUtils';
import JsonServerProvider from './JsonServerProvider';
import {
  ADMIN_ALL,
  ANNOTATION_ALL,
  UPLOADS,
  USERS,
  MODALITIES,
  PROCEDURE,
  REPORTS,
  CHANGE_PASSWORDS,
  DEPARTMENT,
  ORDERS,
  ASYNC_API_ALL
} from './resources';
import CONST_VALUES from '../../utils/constValues';

const hostName = window.location.host;

const fetchHttpClient = (url, options = {}, resource) => {
  if (!options.headers) {
    options.headers = new Headers({
      'content-type': 'application/json'
    });
  }

  options.headers.set('Corporate-Id', process.env.REACT_APP_NAME);
  options.headers.set('Request-Id', uuidv4());
  options.headers.set('Request-Time', moment().format(dateStoreFormat));
  options.headers.set('Authorization', `Bearer ${localStorage.getItem(AUTH_TOKEN)}`);

  return fetchJson(url, options, resource);
};

export const createHeadersFromOptions = (options) => {
  const requestHeaders =
    options.headers ||
    new Headers({
      Accept: 'application/json'
    });
  if (
    !requestHeaders.has('Content-Type') &&
    !(options && (!options.method || options.method === 'GET')) &&
    !(options && options.body && options.body instanceof FormData)
  ) {
    requestHeaders.set('Content-Type', 'application/json');
  }
  if (options.user && options.user.authenticated && options.user.token) {
    requestHeaders.set('Authorization', options.user.token);
  }

  return requestHeaders;
};

const throwFetchError = ({ status, resource, responseCode }) => {
  if (status === MessageCode.NOT_FOUND) {
    throw new Error('Failed to fetch');
  }
  switch (status || responseCode) {
    // case MessageCode.NOT_FOUND:
    case MessageCode.INTERNAL_SERVER_ERROR:
      throw new Error('Failed to fetch');
    case MessageCode.FORBIDDEN:
      throw new Error('commons.error.forbidden');
    case MessageCode.DUPLICATED:
      if (resource === MODALITIES) {
        throw new Error('resources.modality.notification.duplicateModality');
      }
      if (resource === PROCEDURE) {
        throw new Error('resources.procedure.notification.duplicateCode');
      }
      throw new Error('commons.message.duplicate');
    case MessageCode.PARAMETER_REQUIRED:
      throw new Error('commons.message.parameterRequired');
    case MessageCode.BAD_REQUEST:
      switch (resource) {
        case REPORTS:
          throw new Error('page.report.notification.cantCompleteReport');
        case ORDERS:
          throw new Error('page.order.notification.duplicateOrderId');
        case DEPARTMENT:
          throw new Error('commons.message.duplicate');
        case PROCEDURE:
          throw new Error('resources.procedure.notification.duplicateCode');
        case CHANGE_PASSWORDS:
        case USERS:
          break;
        default:
          throw new Error('commons.message.badRequest');
      }

      if (resource === UPLOADS) {
        throw new Error(`resources.${UPLOADS}.notification.invalidFileType`);
      }
      if (resource === CHANGE_PASSWORDS || resource === USERS) {
        break;
      }
      throw new Error('commons.message.badRequest');
    case 452:
      if (resource === CHANGE_PASSWORDS) {
        throw new Error('resources.user.notification.changePassword.currentPassword');
      }
      if (resource === DEPARTMENT) {
        throw new Error('resources.modality.notification.cannotDelete');
      }
      break;
    default:
      break;
  }
};

export const fetchJson = (url, options = {}, resource) => {
  const requestHeaders = createHeadersFromOptions(options);

  return fetch(url, { ...options, headers: requestHeaders }).then(async (response) => {
    const { status, headers } = response;
    throwFetchError({ status, resource });

    const text = await response.text();
    let json = {};
    try {
      json = JSON.parse(text);
    } catch (e) {
      // not json, no big deal
    }
    const { header } = json;
    const { responseCode } = header || {};
    throwFetchError({ resource, responseCode });

    return Promise.resolve({ status, headers, body: text, json });
  });
};

const configs = [
  {
    dataProvider: JsonServerProvider(process.env.REACT_APP_API_URL.replace(CONST_VALUES.HOSTNAME, hostName), fetchHttpClient),
    resources: [...ADMIN_ALL]
  },
  {
    dataProvider: JsonServerProvider(`${process.env.REACT_APP_ASYNC_API_URL.replace(CONST_VALUES.HOSTNAME, hostName)}`, fetchHttpClient),
    resources: [...ASYNC_API_ALL]
  },
  {
    dataProvider: JsonServerProvider(`${process.env.REACT_APP_ANNOTATION_ENDPOINT}`, fetchHttpClient),
    resources: [...ANNOTATION_ALL]
  }
];

const findDataProvider = memoize((resource) => configs.find((dp) => dp.resources.includes(resource)));

const getDataProvider = (type, resource, params) => {
  const mapping = findDataProvider(resource);
  return mapping.dataProvider(type, resource, params);
};

export default {
  getList: (resource, params) => getDataProvider(GET_LIST, resource, params),
  getOne: (resource, params) => getDataProvider(GET_ONE, resource, params),
  getMany: (resource, params) => getDataProvider(GET_MANY, resource, params),
  getManyReference: (resource, params) => getDataProvider(GET_MANY_REFERENCE, resource, params),
  create: (resource, params) => getDataProvider(CREATE, resource, params),
  update: (resource, params) => getDataProvider(UPDATE, resource, params),
  updateMany: (resource, params) => getDataProvider(UPDATE_MANY, resource, params),
  delete: (resource, params) => getDataProvider(DELETE, resource, params),
  deleteMany: (resource, params) => getDataProvider(DELETE_MANY, resource, params)
};
