export default {
    title: {
        list: 'Modalities List',
        edit: 'Edit modality',
        create: 'Add modality',
        information: 'Modality Information',
        config: 'Modality Configuration',
        rentHistory: 'Modality Rental History'
    },
    text: {
        selectRentType: 'Please select rental type of modality',
        owner: 'Owner',
        rent: 'Rent',
        testConnect: 'Test connection'
    },
    button: {
        testConnect: 'Test connection'
    },
    notification: {
        duplicateId: 'ModalityID is already existed.',
        testConnect: {
            success: 'Connect success',
            failure: 'Cannot connect to modality. Please try again or contact Administrators.!'
        },
        create: {
            success: 'Add new modality success',
            failure: 'Cannot add new modality. Please try again or contact Administrators.'
        },
        update: {
            success: 'Update modality\'s information success',
            failure: 'Cannot update modality\'s information. Please try again or contact Administrators.'
        }
    }
};
