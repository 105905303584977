export default {
    title: {
        list: 'Mẫu nội dung KQ',
        create: 'Tạo mẫu nội dung KQ',
        edit: 'Sửa mẫu nội dung KQ'
    },
    notification: {
        empty: 'Nội dung kết quả và kết luận không được để trống',
        duplicateName: 'Tên mẫu nội dung KQ <b>%{templateName}</b> đã tồn tại. Vui lòng nhập tên khác',
        delete: {
            confirm: 'Bạn có chắc chắn muốn xóa mẫu nội dung KQ này không?'
        }
    },
    button: {
        clone: 'Sao chép'
    }
};
