export default {
    ALL: 'All',
    CT: 'CT',
    MR: 'MRI',
    CR: 'Computed Radiography',
    DX: 'Digital Radiography',
    ES: 'Endoscopy',
    ECG: 'Electrocardiography',
    XA: 'X-ray Angiography',
    MG: 'Mammography',
    PT: 'PET CT',
    US: 'Ultrasound',
    OCT: 'Optical Coherence Tomography',
    RF: 'Radio Fluoroscopy',
    Other: 'Other',
    OT: 'Other'
};
