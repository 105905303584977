export default {
    name: 'Ngôn ngữ',
    fields: {
        currentLanguage: 'Ngôn ngữ hiện tại',
        pickLanguage: 'Chọn ngôn ngữ'
    },
    mobile: {
        changeLanguage: 'Chọn ngôn ngữ'
    }
};
