export default {
    title: {
        teleOrders: 'Thống kê kết quả hội chẩn',
        modalities: 'Thống kê ca chụp theo máy chụp',
        studies: 'Thống kê theo ngày chụp',
        doctor: 'Thống kê kết quả theo bác sỹ'
    },
    label: {
        total: 'Tổng số ca chụp',
        statistic: 'Thống kê từ %{startDate} - %{endDate}',
        startDate: 'Ngày bắt đầu',
        endDate: 'Ngày kết thúc',
        groupBy: 'Hiển thị theo'
    },
    notification: {
        reportSummaryNotFound: 'Không tìm thấy bác sĩ nào! Vui lòng thử lại',
        startDateRequired: 'Ngày bắt đầu không được để trống',
        endDateRequired: 'Ngày kết thúc không được để trống'
    },
    weekDay: {
        monday: 'T2',
        tuesday: 'T3',
        wednesday: 'T4',
        thursday: 'T5',
        friday: 'T6',
        saturday: 'T7',
        sunday: 'CN'
    }
};
