export const assignments = {
    name: 'Nhận ca',
    fields: {
        radiologistName: 'Radiologist name',
        radiologistId: 'Radiologist name',
        doctor: 'Doctor',
        title: 'Title',
        creationDate: 'Creation date',
        expiredTime: 'Estimated time to return result'
    }
};
