export default {
    notification: {
        create: {
            successful: 'Tạo thư mục mới thành công',
            failure: 'Không thể tạo thư mục mới. Vui lòng thử lại hoặc liên hệ quản trị viên.'
        },
        edit: {
            successful: 'Cập nhật thư mục thành công',
            failure: 'Không thể cập nhật thư mục %{labelName}. Vui lòng thử lại hoặc liên hệ quản trị viên.'
        },
        createSubLabel: {
            successful: 'Tạo thư mục con cho %{labelName} thành công',
            failure: 'Không thể tạo thư mục con cho %{labelName}. Vui lòng thử lại hoặc liên hệ quản trị viên.'
        },
        remove: {
            confirm: 'Bạn có chắc chắn muốn xóa thư mục %{labelName} không?',
            successful: 'Xóa thư mục %{labelName} thành công',
            failure: 'Không thể xóa thư mục %{labelName}. Vui lòng thử lại hoặc liên hệ quản trị viên.'
        }
    }
};
