export default {
  label: {
    username: 'Username',
    password: 'Password',
    remember: 'Remember'
  },
  button: {
    login: 'Login',
    register: 'Register'
  },
  text: {
    formTitle: 'iPACS Provider Gateway',
    formFooterIPACSCompact: 'Product: iPACS Compact\n',
    formFooter: 'Hotline: 0962.800.362',
    forgetPassword: 'Do you forget password? Click here...',
    currentLogin: 'Current logged in as',
    resetPassword: 'Please contact the administrator to reset your password!'
  }
};
