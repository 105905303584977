import { USER_LOGIN_SUCCESS, CLEAR_STATE } from 'react-admin';
import { Guardian } from 'it-reactjs-ui-components';

import { AUTH_USER, AUTH_AUTHORITIES } from '../resources/authUtils';

let initialState = {};
let authorities = [];
try {
  initialState = JSON.parse(localStorage.getItem(AUTH_USER)) || {};
  authorities = JSON.parse(localStorage.getItem(AUTH_AUTHORITIES));
} catch (error) {
  initialState = {};
  authorities = [];
}
if (initialState.id) {
  Guardian.getInstance().setAuthorities(authorities);
}

export default (previousState = initialState, action) => {
  const { type, payload } = action;
  if (type === USER_LOGIN_SUCCESS) {
    // console.log('user login success', previousState, action);
    const { data } = payload;
    return data || previousState;
  }
  if (type === CLEAR_STATE) {
    return {};
  }
  return previousState;
};
