export const users = {
  name: 'Người dùng',
  title: {
    list: 'Danh sách',
    create: 'Thêm mới',
    edit: 'Thay đổi',
    detail: 'Chi tiết',
    changePassword: 'Đổi mật khẩu'
  },
  fields: {
    uuid: 'Mã số',
    username: 'Tên đăng nhập',
    password: 'Mật khẩu',
    firstName: 'Tên',
    middleName: 'Đệm',
    lastName: 'Họ',
    address: 'Địa chỉ',
    district: 'Quận/Huyện',
    city: 'Thành phố',
    country: 'Quốc gia',
    nationality: 'Quốc gia',
    birthDate: 'Ngày sinh',
    gender: 'Giới tính',
    roleName: 'Quyền đăng nhập',
    accessableModalityGroup: 'Truy cập nhóm máy',
    authorities: 'Quyền',
    fullName: 'Tên đầy đủ',
    degree: 'Chức danh / Học vị',
    race: 'Dân tộc',
    phone: 'Số điện thoại',
    email: 'Email',
    passwordVerify: 'Xác nhận mật khẩu',
    currentPassword: 'Mật khẩu hiện tại',
    newPassword: 'Mật khẩu mới',
    newPasswordVerify: 'Nhập lại mật khẩu',
    department: 'Khoa phòng',
    specialization: 'Chuyên khoa',
    roleId: 'Chức vụ',
    privileges: 'Phân quyền',
    title: 'Chức danh',
    enabled: {
      title: 'Hoạt động',
      active: 'Có',
      inactive: 'Không'
    }
  },
  button: {
    findStaff: 'Chọn thông tin người dùng đã tồn tại',
    updateUserInfo: 'Sửa thông tin',
    updateGroup: 'Sửa nhóm máy',
    addModalityGroup: 'Thêm máy chụp'
  },
  notification: {
    duplicateUsername: 'Tên đăng nhập đã tồn tại, xin thử lại.',
    changePassword: {
      confirm: 'Bạn có chắc chắn muốn đổi mật khẩu mới?',
      currentPassword: 'Mật khẩu hiện tại không đúng, xin thử lại.',
      success: 'Đổi mật khẩu thành công',
      failure: 'Đổi mật khẩu thất bại'
    }
  }
};

export default users;
