export default {
    name: 'Chức vụ',
    fields: {
        id: 'Mã',
        name: 'Vai trò',
        description: 'Mô tả',
        authorities: 'Quyền truy cập',
        roles: 'Chức vụ',
        parentRoleIds: 'Kế thừa',
        edit: 'Sửa'
    },
    title: {
        list: 'Danh sách',
        edit: 'Thay đổi',
        create: 'Thêm mới'
    }
};
